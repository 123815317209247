<template>
  <div class="page">
    <Navbar title="服务订单" type="SMP" />
    <div class="records">
      <van-tabs :active="active" :color="COLOR_M" border="true">
        <van-tab title="待处理" name="wait">
          <van-row
            class="record bdc-b1s-gray"
            v-for="operate in operateList"
            :key="operate.code"
          >
            <van-col span="18" class="content">
              <div class="code">单号：{{ operate.code }}</div>
              <div class="type fc-orange">
                类型：
                <van-tag :color="COLOR_M" plain v-if="operate.type === 'RRD'"
                  >简历批量投递</van-tag
                >
                <van-tag :color="COLOR_M" plain v-if="operate.type === 'IRS'"
                  >公招批量报名</van-tag
                >
                <van-tag :color="COLOR_M" plain v-if="operate.type === 'SCB'"
                  >信息订阅</van-tag
                >
              </div>
              <div class="time">创建时间：{{ operate.createTime }}</div>
            </van-col>
            <van-col span="6">
              <div class="state">
                <van-tag :color="COLOR_M" plain v-if="operate.state === 'WAT'"
                  >待处理</van-tag
                >
              </div>
              <div class="btn">
                <van-button :color="COLOR_M" size="mini" @click="remind()"
                  ><van-icon name="bulb-o" /> 催单</van-button
                >
              </div>
            </van-col>
          </van-row>
          <van-row class="no-record" v-if="operateList.length <= 0">
            <van-col span="24">
              <van-image
                width="103"
                height="103"
                :src="require('../../../../assets/images/home/no-record.png')"
              ></van-image
            ></van-col>
          </van-row>
        </van-tab>
        <van-tab title="进行中" name="process">
          <van-row class="no-record">
            <van-col span="24">
              <van-image
                width="103"
                height="103"
                :src="require('../../../../assets/images/home/no-record.png')"
              ></van-image
            ></van-col>
          </van-row>
        </van-tab>
        <van-tab title="已完成" name="complete">
          <van-row class="no-record">
            <van-col span="24">
              <van-image
                width="103"
                height="103"
                :src="require('../../../../assets/images/home/no-record.png')"
              ></van-image
            ></van-col> </van-row
        ></van-tab>
      </van-tabs>
    </div>
    <Loading :show="loadingShow" />
    <Share ref="share" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
